/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState, useContext } from "react";
import { HeadLine } from "../component";
import dynamic from "next/dynamic";
import LoadingContext from "../../contexts/loaderContext";

const MapWrap = dynamic(() => import("./mapListing"), { ssr: false });

const OurLocation = () => {
  const [loading, setLoading] = useContext(LoadingContext);
  const [location, setLocation] = useState({
    lat: 48.9173205,
    lng: 107.8812576,
  });

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    setLoading(true);
    try {
      const request = await fetch(`/api/get`, {
        method: "POST",
        body: JSON.stringify({ url: "config/detail/MAP_LOCATION" }),
      });
      const checkToken = await request.json();
      if (!checkToken) {
        error(checkToken.message);
        return;
      }
      const loc = JSON.parse(checkToken.value);
      setLocation(loc);
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="pb-24">
      <div className="container">
        <HeadLine
          text="Байршил"
          image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/26a1.png"
          classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
        />
        <div className="w-full flex h-[50vh]">
          <MapWrap location={location} />
        </div>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-7 pt-5">
          <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent">
            <div>
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Даваа
              </span>
              <div className="block">
                <span className="dark:text-jacarta-300 text-sm">
                  10:00 - 18:00
                </span>
              </div>
            </div>
          </div>
          <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent">
            <div>
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Мягмар
              </span>
              <div className="block">
                <span className="dark:text-jacarta-300 text-sm">10:00 - 18:00</span>
              </div>
            </div>
          </div>
          <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent">
            <div>
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Лхагва
              </span>
              <div className="block">
                <span className="dark:text-jacarta-300 text-sm">
                  10:00 - 18:00
                </span>
              </div>
            </div>
          </div>
          <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent">
            <div>
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Пүрэв
              </span>
              <div className="block">
                <span className="dark:text-jacarta-300 text-sm">
                  10:00 - 18:00
                </span>
              </div>
            </div>
          </div>
          <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent">
            <div>
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Баасан
              </span>
              <div className="block">
                <span className="dark:text-jacarta-300 text-sm">
                  10:00 - 18:00
                </span>
              </div>
            </div>
          </div>
          <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent">
            <div>
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Бямба
              </span>
              <div className="block">
                <span className="dark:text-jacarta-300 text-sm">
                  12:00 - 18:00
                </span>
              </div>
            </div>
          </div>
          <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent">
            <div>
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Ням
              </span>
              <div className="block">
                <span className="dark:text-jacarta-300 text-sm">
                  АМАРНА
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurLocation;
